function loadScliderImages(imagesArray, domain) {
  for (var i = 0; i < imagesArray.length; i++) {
    imagesArray[i] = domain + imagesArray[i].img;
  }
  imagesArray = imagesArray.length === 0 ? ["/assets/img/photos/defaultSliderPic.jpg"] : imagesArray;
  $.backstretch(imagesArray, {
    duration: 5000,
    fade: 1000,

  });
}
function showHideSignInmodal() { $('.form-2').toggle(); }

function toggelSignInModal() {
  $('.form-2').toggle();
}
